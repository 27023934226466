import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Link, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import FeatureSection from "../FeatureSection";
import { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { OrgClientInContext } from '../../index'
import { OnboardOrgRequest, Organization, PowerBiConfig } from "../types";
import ClosableToastAction from "../basic/ClosableToastAction";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import DeployReportButtonAndDialog from './DeployReportButtonAndDialog'

function EnableInsightsSwitchAndDialog(props: { org: Organization, isEnabled: boolean }) {
    const client = useContext(OrgClientInContext)
    const { enqueueSnackbar } = useSnackbar()

    const [expanded, setExpanded] = useState<string | false>("general");
    const [isEnabled, setIsEnabled] = useState(props.isEnabled)
    const [dialogIsOpen, setDialogIsOpen] = useState(false)

    const [onboardRequest, setOnboardRequest] = useState<OnboardOrgRequest>({
        workspaceName: "Insights [PROD] " + extractSubdomainFromUrl(props.org.cms_api_host),
        tenantId: "26548337-0c41-444c-8c8e-24c45228a4d5",
        clientId: "f03833fb-d399-4eb5-8878-303ae9794203",
        capacityId: "3F120762-E552-49AD-A3E2-46DBF645B39B",
        adminGroupId: "bd4ddc62-d745-42de-b024-0f45407738ef",
        appSecret: "",
        sourceWorkspaceId: "77829d9f-aafb-4894-a31a-6ba6291ec038",
        displayReportId: "3d50595c-0df9-4711-9974-5d767f231ee9",
        datasetReportId: "6e952a1b-9df0-42cb-b8d2-940e42e33788",
        gatewayId: "8427f42a-ba71-4bbd-b0e2-ae991d2cc762",
        gatewayUsername: "Service-PowerBi-Prod",
        gatewayPassword: "",
        connectionString: "",
        databaseName: "",
        refreshSchedule: {
            localTimeZoneId: "UTC",
            days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            times: ["00:00", "12:00"]
        }
    })

    const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked && !isEnabled) {
            setDialogIsOpen(true)
        }
    }

    const isOnboardRequestValid = (): boolean => {
        return notNullOrEmpty(onboardRequest.workspaceName)
            && notNullOrEmpty(onboardRequest.connectionString)
            && notNullOrEmpty(onboardRequest.databaseName)
            && notNullOrEmpty(onboardRequest.gatewayId)
            && notNullOrEmpty(onboardRequest.gatewayUsername)
            && notNullOrEmpty(onboardRequest.gatewayPassword)
            && notNullOrEmpty(onboardRequest.displayReportId)
            && notNullOrEmpty(onboardRequest.sourceWorkspaceId)
            && notNullOrEmpty(onboardRequest.refreshSchedule)
            && notNullOrEmpty(onboardRequest.datasetReportId)
    }

    const onboardOrg = () => {
        setIsEnabled(true)
        setDialogIsOpen(false)
        client.powerbi.onboard(onboardRequest)
        enqueueSnackbar("The onboarding process has started and may take several minutes", { variant: "info", persist: true, action: ClosableToastAction })
    }

    const handleAccordionChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const DayCheckBox = (props: { dayName: string }) => {
        return <FormControlLabel 
            control={<Checkbox 
                checked={ onboardRequest.refreshSchedule?.days?.includes(props.dayName) }             
                onChange={e => {
                    setOnboardRequest(prevState => {
                        let newDays = e.target.checked ? prevState.refreshSchedule?.days?.concat([props.dayName]) 
                            : prevState.refreshSchedule?.days?.filter(d => d != props.dayName)

                        return {
                            ...prevState,
                            refreshSchedule: {
                                ...prevState.refreshSchedule,
                                days: newDays
                            }
                        }})
                }}/>
            } 
            label={props.dayName} />
    }

    return (
        <>
        <Switch checked={isEnabled} disabled={isEnabled} onChange={handleSwitch} />
        <Dialog open={dialogIsOpen}>
            <DialogTitle>Enable Power BI Insights</DialogTitle>
            <DialogContent>
                <Alert severity="info" sx={{ marginBottom: 4 }}>
                    Enabling Power BI Insights requires help from an expert. If you'd like to enable Insights, please ask for assistance.
                </Alert>
                <Accordion expanded={expanded === 'general'} onChange={handleAccordionChange('general')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>General</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1.5}>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Workspace Name"
                                    value={ onboardRequest.workspaceName }
                                    helperText="The name of the workspace that will be created"
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            workspaceName: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Tentant ID"
                                    value={ onboardRequest.tenantId }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            tenantId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Client ID"
                                    value={ onboardRequest.clientId }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            clientId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Admin Group ID"
                                    value={ onboardRequest.adminGroupId }
                                    helperText="The group to assign to the workspace"
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            adminGroupId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Capacity ID"
                                    value={ onboardRequest.capacityId }
                                    helperText="The capacity to assign to the workspace"
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            capacityId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="App Secret"
                                    value={ onboardRequest.appSecret }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            appSecret: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>                                                                                                                                       
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'report'} onChange={handleAccordionChange('report')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Report and Dataset
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1.5}>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Source Workspace ID"
                                    value={ onboardRequest.sourceWorkspaceId }
                                    helperText="The workspace location where the source files live"
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            sourceWorkspaceId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Dataset Report ID"
                                    value={ onboardRequest.datasetReportId }
                                    helperText="The report bound to the dataset"
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            datasetReportId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Display Report ID"
                                    value={ onboardRequest.displayReportId }
                                    helperText="The report to display within the CCMS"
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            displayReportId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Gateway ID"
                                    helperText="Available through the Power BI web interface"
                                    value={ onboardRequest.gatewayId }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            gatewayId: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Gateway Username"
                                    helperText="This is a Windows service credential"
                                    value={ onboardRequest.gatewayUsername }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            gatewayUsername: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Gateway Password"
                                    helperText="This is a Windows service credential"
                                    value={ onboardRequest.gatewayPassword }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            gatewayPassword: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>                                                        
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Connection String"
                                    placeholder="dsn=QA NIGHTLY"
                                    value={ onboardRequest.connectionString }
                                    helperText="This is the DSN that shows on the ODBC connector"
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            connectionString: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Database"
                                    helperText="This is the relational database/schema of the org"
                                    value={ onboardRequest.databaseName }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            databaseName: e.target.value
                                        }))
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'refresh'} onChange={handleAccordionChange('refresh')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Refresh Schedule</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">Days</FormLabel>
                                <FormGroup>
                                    <Stack direction="row">
                                        <Stack>
                                            <DayCheckBox dayName="Monday" />
                                            <DayCheckBox dayName="Tuesday" />
                                            <DayCheckBox dayName="Wednesday" />
                                        </Stack>
                                        <Stack>
                                            <DayCheckBox dayName="Thursday" />
                                            <DayCheckBox dayName="Friday" />
                                            <DayCheckBox dayName="Saturday" />
                                        </Stack>
                                        <Stack>
                                            <DayCheckBox dayName="Sunday" />
                                        </Stack>
                                    </Stack>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid container spacing={1.5}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Time Zone ID"
                                    value={ onboardRequest.refreshSchedule?.localTimeZoneId }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            refreshSchedule: {
                                                ...prevState.refreshSchedule,
                                                localTimeZoneId: e.target.value
                                            }
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Times"
                                    helperText="The times to run the refresh on each day. Expressed as CSV, 24hr 00:00"
                                    value={ onboardRequest.refreshSchedule?.times }
                                    onChange={e => {
                                        setOnboardRequest(prevState => ({
                                            ...prevState,
                                            refreshSchedule: {
                                                ...prevState.refreshSchedule,
                                                times: e.target.value.trim().split(",")
                                            }
                                        }))
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setDialogIsOpen(false)}>
                    Cancel
                </Button>
                <Button autoFocus onClick={onboardOrg} disabled={!isOnboardRequestValid()}>
                    Enable
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

function notNullOrEmpty(thing: any): boolean {
    return typeof thing != "undefined" && thing
}

function extractSubdomainFromUrl(url: string): string {
    url = url.replace("http://", "")
    url = url.replace("https://", "")
    
    let parts = url.split(".")
    return parts.slice(0, parts.length - 2).join(".")
}

function PowerBiWorkspaceDetails(props: { org: Organization, config: PowerBiConfig }) {
    return (
        <TableContainer component={Paper} sx={{ maxWidth: 650 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Workspace ID</TableCell>
                        <TableCell align="right">
                            <Link href={`https://app.powerbi.com/groups/${props.config.workspaceId}`} target="_blank" color="inherit" className="uuid">{ props.config.workspaceId }</Link>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Displayed Report ID</TableCell>
                        <TableCell align="right"><div className="uuid">{ props.config.reportId }</div><DeployReportButtonAndDialog org = { props.org } /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dataset ID</TableCell>
                        <TableCell align="right"><span className="uuid">{ props.config.datasetId }</span></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default function Insights() {
    const client = useContext(OrgClientInContext)

    const [powerBiConfig, setPowerBiConfig] = useState<PowerBiConfig | undefined>(undefined)

    useEffect(() => {
        client.powerbi.getConfig().then((config: PowerBiConfig) => {
            setPowerBiConfig(config)
        })
    }, [])

    let placeHolderSkeleton =
        <FeatureSection
            featureName=""
            featureDescription=""
            content={""}
        />

    let isEnabled = powerBiConfig != undefined && powerBiConfig.workspaceId != null && powerBiConfig.reportId != null

    return (
        powerBiConfig == undefined ? placeHolderSkeleton :
            <FeatureSection
                featureName="Insights (Power BI)"
                featureDescription="Insights is a screen accessible under the main hamburger menu that shows various charts about how the customer is using the product. It is disabled by default."
                content={
                    isEnabled ? <PowerBiWorkspaceDetails org={client.getOrg()} config={powerBiConfig} /> : <div />
                }
                control={<EnableInsightsSwitchAndDialog org={client.getOrg()} isEnabled={isEnabled} />}
            />
    )
}