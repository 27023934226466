import styles from "./FeatureSection.module.css"
import Skeleton from "react-loading-skeleton"

interface FeatureSectionProps {
    featureName?: string,
    featureDescription?: string,
    control?: any,
    content?: any
}

export default function FeatureSection(props: FeatureSectionProps) {
    return (
        <div className={ styles.feature }>
            <div className={ styles.header }>
                <div className={ styles.details }>
                    <div className={ styles.name }>{ props.featureName || <Skeleton width="40%" /> }</div>
                    <div className={ styles.description }>{ props.featureDescription || <Skeleton /> }</div>
                </div>
                <div className={ styles.control }>
                    { props.control }
                </div>
            </div>
            <div className={ styles.content }>
                { props.content || <Skeleton height="8rem" /> }
            </div>
        </div>
    )
}