import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import OrgPage from './modules/OrgPage';
import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom'
import { Organization } from './modules/types';
import { OrgClient, getOrgByUuid } from './Heretto';
import { SkeletonTheme } from 'react-loading-skeleton'
import { SnackbarProvider } from 'notistack';
import "./global.css"
import FailedToConnectCcms from './modules/FailedToConnectCmsToast'

const OrgClientInContext = React.createContext<any>("instance") // todo: "any" needs to updated with an interface. otherwise autocomplete and type info wont be available 

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SnackbarProvider Components={{ FailedToConnectCcms }} maxSnack={3} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}>
        <SkeletonTheme baseColor="#CDCDCD" highlightColor="#e0e0e0">
          <App />
        </SkeletonTheme>
      </SnackbarProvider>
    ),
    children: [
      {
        path: "orgs/:orgUuid",
        element: <OrgPageWrapped />
      }
    ]
  }
])

function OrgPageWrapped() {
  let orgUuid: string = useParams().orgUuid!
  let [ org, setOrg ] = useState<Organization | null>()

  /**
   * This fixes a state issue where the orgUuid and org object 
   * are out of sync while the new org info is fetched.
   * I wonder if there's a better way to handle cases like this
   */
  if(org != null && orgUuid != org.org_uuid) {
    setOrg(null)
  }

  useEffect(() => {
    getOrgByUuid(orgUuid).then(setOrg)
  }, [ orgUuid ])

  return (
    <> 
      {
        org != null &&
          <OrgClientInContext.Provider value={ OrgClient(org) }>
            <OrgPage />
          </OrgClientInContext.Provider>
      }
    </>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <RouterProvider router={ router } />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

(function disableStupidScrollingFunctionalityOnHtml5InputTypeNumber() {
  // if an input of type="number" is focused and the user uses the scroll wheel, the number is incremented or decremented...
  // very stupid since the user can accidentally change the value when they are likely just trying to scroll the page
  document.addEventListener("wheel", function(event){
    const numberInput = document.activeElement as HTMLInputElement
    if (numberInput.type === "number") {
      numberInput.blur();
    }
  })
})()

export {
  OrgClientInContext as OrgClientInContext
}