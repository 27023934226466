import React from "react"
import { SnackbarContent } from "notistack"

declare module 'notistack' {
    interface VariantOverrides {
        FailedToConnectCcms: true
    }
}

interface FailedToConnectCmsProps {
}

const FailedToConnectCmsToast = React.forwardRef<HTMLDivElement, FailedToConnectCmsProps>((props, ref) =>
    <SnackbarContent ref={ref} role="alert" {...props} className="error-toast">
        <span className="title">Can't connect to the CCMS</span>
        <br/>
        <span className="subtitle">Try troubleshooting</span>
        <ul>
            <li>Check that the CCMS is online</li>
            <li>Verify that the CCMS version is 23.2.7 or greater</li>
            <li>Ensure you have a local account where the username matches your @heretto work email (@jorsek doesn't work) and that it has the System Administration role</li>
        </ul>
    </SnackbarContent>)

  
export default FailedToConnectCmsToast