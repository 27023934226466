type PromiseAction = "resolve" | "reject"

export function ensureMinDelayThenResolve(minDurationMs: number) {
    return ensureMinDelayThen(minDurationMs, "resolve")
}

export function ensureMinDelayThenReject(minDurationMs: number) {
    return ensureMinDelayThen(minDurationMs, "reject")
}

/**
 * Can be used to limit how fast a promise can resolve or reject.
 * Usage:
 * someAsyncOperationThatMayHappenTooQuickly()
 *  .then(ensureMinDelayThen(2000, "resolve"))
 *  .then(doTheTask())
 * 
 * someAsyncOperationThatMayFailTooQuickly()
 *  .then(ensureMinDelayThen(2000, "reject"))
 *  .then(doTheTask())
 * @param minDurationMs the minimum time in milliseconds that the operation should run for
 */
function ensureMinDelayThen(minDurationMs: number, action: PromiseAction) {
    let startTime = new Date().getTime()

    return (result: any) => {
        let durationMs = new Date().getTime() - startTime

        return new Promise((resolve, reject) => {
            if(durationMs < minDurationMs) {
                let timeLeft = minDurationMs - durationMs
                setTimeout(() => {
                    ({ resolve, reject })[action](result)
                }, timeLeft)
            } else {
                ({ resolve, reject })[action](result)
            }
        })
    }
}