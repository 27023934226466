import { useEffect, useRef } from "react";

declare global {
    var google: any
}

export interface GoogleSigninResponse {
    credential: string
}

export interface GoogleSigninListener {
    (response: GoogleSigninResponse): void
}

/**
 * Encapuslates the Signin with Google library setup and adds some callback
 * functionality to better support useEffect react pattern
 * 
 * https://developers.google.com/identity/gsi/web/guides/overview
 */
class GoogleSSO {
    private readonly clientId: string
    private callbacks: Array<GoogleSigninListener> = []
    private isGoogleLibInitialized = false

    constructor(clientId: string) {
        this.clientId = clientId
    }

    /**
     * Initializes the "Signin with Google" library
     * It's safe to call multiple times but will only do the work once
     * @returns a Promise<boolean> that resolves once the library is safe to use. 
     * the boolean indicates if the library was loaded for the first time
     */
    init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if(this.isGoogleLibInitialized) {
                resolve(false)
                return
            }

            const loadScript = () => {
                const script = document.createElement('script');
                script.src = "https://accounts.google.com/gsi/client"
                script.async = true;
        
                script.onload = () => {
                    window.google.accounts.id.initialize({
                        client_id: this.clientId,
                        callback: this.emitOnUserSignin.bind(this)
                    })
    
                    this.isGoogleLibInitialized = true
                    resolve(true)
                }
              
                document.body.appendChild(script)
            }

            if(document.readyState === "loading") {
                document.addEventListener("DOMContentLoaded", loadScript)
            } else {
                loadScript()
            }
        })

    }

    private emitOnUserSignin(response: GoogleSigninResponse) {
        this.callbacks.forEach(c => c(response))
    }

    addOnUserSigninListener(listener: GoogleSigninListener) {
        this.callbacks.push(listener)
    }

    removeOnUserSigninListener(listener: GoogleSigninListener) {
        this.callbacks.splice(this.callbacks.indexOf(listener), 1)
    }
}

const googleSignin = new GoogleSSO(process.env.REACT_APP_GOOGLE_CLIENT_ID!)

/**
 * https://developers.google.com/identity/gsi/web/guides/display-button
 */
export function GoogleSSOBtn(props: { onSignin: GoogleSigninListener }) {
    let googleBtn = useRef(null)

    useEffect(() => {
        googleSignin.addOnUserSigninListener(props.onSignin)

        googleSignin.init().then(() => {
            window.google.accounts.id.renderButton(
                googleBtn.current,
                { theme: "outline", size: "medium" }
            )
        })

        return () => googleSignin.removeOnUserSigninListener(props.onSignin)
    })

    return (
        <div ref={ googleBtn } />
    )
}