import { ReactNode } from "react"
import styles from "./Panel.module.css"

interface Props {
     children: ReactNode,
     className?: string
}

export default function Panel({ children, className = ""}: Props) {
    return (
        <div className={`${styles.panel} ${className}`}>
            { children }
        </div>
    )
}