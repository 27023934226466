import { useState } from "react"
import styles from "./ListSelectable.module.css"
import Skeleton from 'react-loading-skeleton'

function Card(props: { title?: string, description?: string, isSelected: boolean, onClick?: () => void }) {

    let className = `${ styles.card } ${ props.isSelected ? styles.cardSelected : ""}`

    return (
        <div onClick={ props.onClick } className={ className }>
            <div className={ styles.title } >{ props.title || <Skeleton /> }</div>
            <div className={ styles.description }>{ props.description || <Skeleton /> }</div>
        </div>
    )
}

interface ListSelectableProps<T> {
    entries: Array<T>,
    onSelect?: (e: T) => void,
    titleProducer: (e: T) => string | undefined,
    descriptionProducer: (e: T) => string | undefined,
    keyProducer: (e: T) => any
}

export default function ListSelectable<T>(props: ListSelectableProps<T>) {
    const [selectedEntry, setSelectedEntry] = useState<T>()

    function handleOnClick(entry: T) {
        setSelectedEntry(entry)
        props.onSelect?.(entry)
    }

    return (
        <div className={ styles.list }>
            { 
                props.entries.map(e => 
                    <Card 
                        title={ props.titleProducer(e) } 
                        description={ props.descriptionProducer(e) } 
                        isSelected={ e === selectedEntry }
                        onClick={ () => handleOnClick(e) }
                        key={ props.keyProducer(e) }
                    />
                 )
            }
        </div>
    )
}