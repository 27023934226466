import { EttoPackage } from "./modules/features/EttoPackageFeature"
import { InAppNotification, Organization, CcmsVersionInfo, OnboardOrgRequest, DeployReportRequest } from "./modules/types"

let serverAddress = process.env.REACT_APP_CCMS_PROXY_URL

interface NetworkError {
    status: number,
    fromGateway: boolean
}

let globalNotOkHandler: (error: NetworkError) => void
let orgCache: Array<Organization>

/**
 * It's the Fetch method but wrapped with some global error handling
 */
const wFetch = (url: string, options: object, throwOnNotOk: boolean = false): Promise<any> => {
    return fetch(url, options).then(resp => {
        if(!resp.ok) {
            globalNotOkHandler?.({
                status: resp.status,
                fromGateway: resp.headers.has("from-gateway")
            })

            if(throwOnNotOk) {
                throw "request failed"
            }
        }

        return resp
    })
}

/**
 * Returns Http request options that should be used for all requests. 
 * Includes the required authorization header.
 */
const newDefaultOptions = (method: string, orgUuid?: string): any => { 
    let googleJwt = window.sessionStorage.getItem("google_jwt")
    let headers: { [header: string]: string } = {}

    headers["Authorization"] = `Bearer ${ googleJwt }`

    if(orgUuid) {
        headers["target-org-uuid"] = orgUuid
    }

    return {
        method,
        headers
    }
}

export function setGlobalNotOkHandler(handler: (error: NetworkError) => void) {
    globalNotOkHandler = handler
}

export function getAllOrgs(): Promise<Array<Organization>> {
    if(orgCache == null) {
        return wFetch(`${ serverAddress }/organizations`, newDefaultOptions("GET"))
            .then(resp => resp.json())
            .then(json => orgCache = json) as Promise<Array<Organization>>
    }

    return Promise.resolve(orgCache)
}

export function getOrgByUuid(uuid: string): Promise<Organization | undefined> {
    return getAllOrgs().then(orgs => orgs.find(o => o.org_uuid === uuid))
}

export function getTokenStatus(): Promise<Response> {
    return fetch(`${ serverAddress }/token_status`, newDefaultOptions("GET"))
}

export function OrgClient(org: Organization) {
    const newOptions = (method: string) => newDefaultOptions(method, org.org_uuid)

    return {
        getOrg(): Organization {
            return org
        },
        
        notifications: {
            getMyNotifications(): Promise<InAppNotification> {
                const url = `${ serverAddress }/ezdnxtgen/api/in-app-notifications/get-my-notifications`;
                    
                return wFetch(url, newOptions("GET"))
                    .then(resp => resp.json())
            },
            createUpgradeNotification(startTimeUtc: number, duration: number) {            
                const url = `${ serverAddress }/ezdnxtgen/api/in-app-notifications/create-upgrade-notification?time-of-upgrade=${ startTimeUtc }&duration=${ duration }`;

                return wFetch(url, newOptions("PUT"))
            },
            removeNotification(notificationId: string) {
                const url = `${ serverAddress }/ezdnxtgen/api/in-app-notifications/remove-notification?notification-id=` + notificationId

                return wFetch(url, newOptions("DELETE"))
            }
        },

        status: {
            system() {
                const url = `${ serverAddress }/status`

                return wFetch(url, newOptions("GET")).then(resp => resp.json());
            },
            exist() {
                const url = `${ serverAddress }/status`

                return wFetch(url, newOptions("GET"));
            }
        },

        objects: {
            metrics: {
                get(names: Array<string>) {
                    const url = `${ serverAddress }/ezdnxtgen/api/objects/metrics?${ arrayToQueryString("names", names) }`
                    return wFetch(url, newOptions("GET")).then(resp => resp.json())
                }
            }
        },

        powerbi: {
            onboard(request: OnboardOrgRequest) {
                const url = `${ serverAddress }/ezdnxtgen/api/powerbi/onboard`
                let options = newOptions("POST");
                options.body = JSON.stringify(request);
                options.headers["Content-Type"] = "application/json"

                return wFetch(url, options);
            },

            deployReport(request: DeployReportRequest) {
                const url = `${ serverAddress }/ezdnxtgen/api/powerbi/report`
                let options = newOptions("POST");
                options.body = JSON.stringify(request);
                options.headers["Content-Type"] = "application/json"

                return wFetch(url, options);
            },

            getConfig(): any {
                const url = `${ serverAddress }/ezdnxtgen/api/powerbi/config`
                return wFetch(url, newOptions("GET")).then(resp => resp.json())
            }
        },

        contentApi: {
            getDeployments() {
                const url = `${ serverAddress }/ezdnxtgen/api/content-api/deployments`
                // this endpoint will return an error object if the ccms is not configured for deployments
                // callers should check for the error object and handle unconfigured ccms' gracefully.
                return fetch(url, newOptions("GET")).then(resp => resp.json())
            }
        },

        etto: {
            getPackage(): Promise<EttoPackage | null> {
                const url = `${ serverAddress }/ezdnxtgen/api/etto/package`
                let options = newOptions("GET");
                options.headers["Content-Type"] = "application/json"
                return fetch(url, options).then(resp => {
                    if(resp.status === 404) {
                        return null
                    } else if(resp.status === 200) {
                        return resp.json()
                    } else {
                        throw "can't get package"
                    }
                })
            },
            setPackage(ettoPackage: EttoPackage) {
                const url = `${ serverAddress }/ezdnxtgen/api/etto/package`
                let options = newOptions("POST");
                options.body = JSON.stringify(ettoPackage);
                options.headers["Content-Type"] = "application/json"
                return wFetch(url, options, true);
            }
        },

        getVersionInfo(signal: AbortSignal): Promise<CcmsVersionInfo> {
            return fetch(`${ serverAddress }/ezdnxtgen/api/version-info`, { ...newOptions("GET"), signal })
                .then(resp => {
                    if(resp.status === 500) {
                        throw "request failed"
                    }

                    return resp.json()
                })
        }
    } 
}

/*
  Given an array of values like:
  ["value1", "value2", "value3"]
  Produce a string like:
  "param=value1&param=value2&param=value3"
*/
function arrayToQueryString(name: string, values: Array<any>) {
  return values.map(value => `${ name }=${ encodeURIComponent(value) }`).join("&");
}