import { Button } from "@mui/material"
import { closeSnackbar, SnackbarKey } from "notistack"

export default (snackbarId: SnackbarKey) => {
    return (
      <Button onClick={() => { closeSnackbar(snackbarId) }} style={ { color: "white" }} variant="outlined">
        ok
      </Button>
  )
}
  