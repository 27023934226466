import { GoogleSSOBtn, GoogleSigninListener } from "./GoogleSSO";
import styles from "./Login.module.css"
import logo from "../heretto-logo-small.webp"

export default function Login(props: { onSignin: GoogleSigninListener }) {
    return (
        <div className={ styles.container }>
            <div className={ styles.login }>
                <img src={ logo } className={ styles.logo } alt="" />
                <div className={ styles.loginPanel }>
                    <h1>Admin Dashboard</h1>
                    <GoogleSSOBtn onSignin={ props.onSignin }/>
                </div>
            </div>
        </div>
    )
}