import { Chip, Tabs, Tooltip } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import InAppNotifications from './InAppNotifications'
import Metrics from './Metrics'
import CheckIcon from '@mui/icons-material/Check'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import { OrgClientInContext } from '../index'
import { CcmsVersionInfo } from "./types"
import FeatureSection from './FeatureSection'
import Skeleton from "react-loading-skeleton"
import { SkeletonTheme } from "react-loading-skeleton"
import { useSnackbar, SnackbarKey } from 'notistack'
import { ensureMinDelayThenReject, ensureMinDelayThenResolve } from '../Utils'
import Insights from './features/Insights'
import ContentDeploymentFeature from './features/ContentDeploymentFeature'
import EttoPackageFeature from './features/EttoPackageFeature'
import Tab from '@mui/material/Tab';


interface Feature {
    name: string,
    minVersion: string,
    component: any,
    tabName?: string
}

const features: Feature[] = [
    {
        name: "In-app Notifications",
        minVersion: "22.10",
        component: () => <InAppNotifications />,
    },
    {
        name: "Insights",
        tabName: "Insights",
        minVersion: "22.10",
        component: () => <Insights />
    },
    {
        name: "Content Deployment",
        minVersion: "22.10",
        component: () => <ContentDeploymentFeature />
    },
    {
        name: "Etto",
        tabName: "Etto",
        minVersion: "22.10",
        component: () => <EttoPackageFeature />
    }
    // {
    //     name: "Metrics",
    //     minVersion: "21.20",
    //     component: () => <Metrics />
    // }
]

let featuresByTabName = groupFeaturesByTabName(features)
let numberOfFeaturesForFirstTab = featuresByTabName.entries().next().value.length
let skeletonFeatures = Array.from(Array(numberOfFeaturesForFirstTab)).map((_, i) => <FeatureSection key={ i } />)

export default function OrgPage() {
    const client = useContext(OrgClientInContext)
    const org = client.getOrg()
    const [ ccmsVersionInfo, setCcmsVersionInfo ] = useState<CcmsVersionInfo | null>()
    const [ orgNotAccessible, setOrgNotAccessible ] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    useEffect(() => {
        let ac = new AbortController()
        let snackbarId: SnackbarKey

        client.getVersionInfo(ac.signal)
            .then(ensureMinDelayThenResolve(1000))
            .then(setCcmsVersionInfo)
            .catch(ensureMinDelayThenReject(2000))
            .catch((e: any) => {
                if(ac.signal.aborted) { // if the request was cancelled, ex, by the user switching to a different org, this isnt an error
                    return
                }

                setOrgNotAccessible(true)
                snackbarId = enqueueSnackbar("The CCMS is not accessible at the moment. Is it online?", { variant: "FailedToConnectCcms", persist: true })
            })

        return () => {
            closeSnackbar(snackbarId)
            /**
             * Cancel the request if the user switches orgs quickly. Otherwise there's a case where the selected org does not match the
             * loaded org - depending on the order that the requests return. The AbortController is an HTML5 thing.
             */
            ac.abort()
        }
    }, [ client ])

    let isLoading = ccmsVersionInfo == null && orgNotAccessible == false

    let availableFeatures = (): Feature[] => features
        .filter(f => isVersionGreaterThanOrEqual(ccmsVersionInfo?.implementationVersion!, f.minVersion))

    let unavailableFeatures = (): Feature[] => features
        .filter(f => !availableFeatures().includes(f))

    let noFeaturesAvailable = () =>
        <div>
            <div>This organization does not support any admin features</div>
            <UnavailableFeaturesTable features={ unavailableFeatures() } />
        </div>       

    let featureComponents = () => availableFeatures().length === 0
        ? noFeaturesAvailable()
        : availableFeatures().map(f => f.component())

    function OrgStatusChips() {
        let allFeaturesAvailableChip = 
            <Tooltip title="This instance is up-to-date enough so all admin features are available">
                <Chip icon={ <CheckIcon /> } label="All features available" />
            </Tooltip>    

        let someFeaturesUnavailableChip = () => 
            <Tooltip title={ <UnavailableFeaturesTable features={ unavailableFeatures() } /> }>
                <Chip icon={ <DoDisturbIcon /> } label="Some features unavailable" color="warning" />
            </Tooltip>

        let chipSkeleton = <Skeleton width="150px" height="32px" />

        return (
            <div className="chip-list">
                {
                    (isLoading || orgNotAccessible) ? chipSkeleton :
                        <Tooltip title="The current CCMS version">
                            <Chip label={`CCMS v${ ccmsVersionInfo?.implementationVersion }`} />
                        </Tooltip>
                }

                {
                    (isLoading || orgNotAccessible) ? chipSkeleton : // how can we improve the readablity of this?
                        unavailableFeatures().length === 0 ? allFeaturesAvailableChip : 
                                unavailableFeatures().length < features.length && someFeaturesUnavailableChip()
                }
            </div>
        )
    }

    let containerClass = "container" + (orgNotAccessible ? " error" : "")
    let baseSkeletonColor = orgNotAccessible ? "#fdb9b9d4" : "#CDCDCD"
    
    return (
        <SkeletonTheme baseColor={ baseSkeletonColor } highlightColor="#e0e0e0" enableAnimation={ !orgNotAccessible }>
            <div className={ containerClass }>
                <span className="title">{ org?.org_id }</span>
                <span className="hint short-uuid" title={ `Org UUID: ${ org?.org_uuid }`}>{ org?.org_uuid }</span>            
                <a className="subtitle" href={ org?.cms_api_host } target="_blank">{ org?.cms_api_host }</a>
                <OrgStatusChips />
                
                {
                    (isLoading || orgNotAccessible) && skeletonFeatures
                }
                {
                    (!isLoading && !orgNotAccessible) && <TabbedFeatures />
                }
            </div>
        </SkeletonTheme>
    )
}

function groupFeaturesByTabName(features: Feature[]): Map<String, Array<Feature>> {
    let tabs = new Map();

    for(let feature of features) {
        let tabName = feature.tabName == null ? "Details" : feature.tabName
        let featuresForTab = tabs.get(tabName)
        if(featuresForTab == undefined) {
            featuresForTab = []
            tabs.set(tabName, featuresForTab)
        }
        featuresForTab.push(feature)
    }

    return tabs;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            children
        )}
      </div>
    );
}

function TabbedFeatures() {
    let [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
    
    var tabNames = Array.from(featuresByTabName.keys())
    var tabsContent = Array.from(featuresByTabName.values()) // an array of arrays of features. root array indexes are the tab indexes
    return (
        <>
            <Tabs value={currentTabIndex} onChange={ (e, v) => setCurrentTabIndex(v) } aria-label="basic tabs example">
                { tabNames.map((name, i) => <Tab label={name} key={i} />)}
            </Tabs>
            {
                tabsContent.map((featureSetForTab, i) => 
                    <CustomTabPanel value={currentTabIndex} index={i} key={i}>
                        { featureSetForTab.map(f => f.component() ) }
                    </CustomTabPanel>)
            }
        </>
    )
}

function isVersionGreaterThanOrEqual(version1: string, version2: string): boolean {
    return true; // todo implement this by comparing each of the parts of the version individually
}

interface UnavailableFeaturesTableProps {
    features: Array<Feature>
}

function UnavailableFeaturesTable(props: UnavailableFeaturesTableProps) {
    return (
        <table>
            <thead>
                <tr>
                    <th align='left'>Feature</th>
                    <th>CCMS Min version</th>
                </tr>
            </thead>         
            <tbody>
                { props.features.map(f => 
                    <tr>
                        <td>
                            { f.name }
                        </td>
                        <td>
                            { f.minVersion }
                        </td>
                    </tr>)}
            </tbody>
        </table>
    )
}