import { Paper, Accordion, AccordionDetails, AccordionSummary, Alert, Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Radio, Button, Box, Typography } from "@mui/material"
import FeatureSection from "../FeatureSection";
import { useContext, useEffect, useState } from "react";
import { OrgClientInContext } from '../../index'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from "notistack";

type UserCountComputed = (userCount: number) => number
type NumberOrUserCountComputed = number | UserCountComputed

// A "template" package. Describes an abstract package that may define computed values
interface PackageTemplate {
    packageName: string,
    maxInputTokens: NumberOrUserCountComputed,
    maxOutputTokens: NumberOrUserCountComputed,
    maxUserPromptChars: NumberOrUserCountComputed,
    maxWordsInEditingTopic: NumberOrUserCountComputed,
    maxGuidanceChars: NumberOrUserCountComputed,
    userBased: boolean
}

// A concrete package with any computed fields resolved
export interface EttoPackage {
    packageName: string,
    userCount: number | null
    maxInputTokens: number | null,
    maxOutputTokens: number | null,
    maxUserPromptChars: number | null,
    maxWordsInEditingTopic: number | null,
    maxGuidanceChars: number | null
}

const basePackageTemplate = {
    maxInputTokens: function(userCount: number) { return userCount * 600000 },
    maxOutputTokens: function(userCount: number) { return userCount * 100000 },
    maxUserPromptChars: 1500,
    maxWordsInEditingTopic: 500,
    maxGuidanceChars: 1500
}

/**
 * Limitations when editing the package templates after customers have packages set:
 * 1. Changing package names will prevent the UI from knowing/displaying the "active" template
 * 2. Changing the values of the package won't automatically propagate to customers using said template and this will need manual steps
 */
const package_templates: Array<PackageTemplate> = [
    {
        ...basePackageTemplate,
        ...{
            packageName: "Standard",
            userBased: false,
            maxInputTokens: 600000,
            maxOutputTokens: 100000
            // override base template values here
        }
    },
    {
        ...basePackageTemplate,
        ...{
            packageName: "Premium Tier 1",
            userBased: true
            // override base template values here
        }
    }
]

/**
 * Takes a PackageTemplate and a user count and constructs a Package, resolving any computed package values
 */
function constructPackage(packageTemplate: PackageTemplate, userCount: number): EttoPackage {
    let resolveTemplateField = (fieldValue: NumberOrUserCountComputed): number => {
        if(typeof fieldValue === "function") {
            return fieldValue(userCount)
        }
        return fieldValue
    }

    let ettoPackage: any = {}

    Object.entries(packageTemplate).forEach(([key, value]: [string, NumberOrUserCountComputed]) => {
        if(key == "userBased") { // "userBased" does not belong on a concrete template
            return
        }
        ettoPackage[key] = resolveTemplateField(value)
    })

    ettoPackage.userCount = userCount
    return ettoPackage
}

function renderPackageTemplateFieldValue(value: any): any {
    if(typeof value === "function") {
        return "Per License"
    }
    return value
}

function EttoPackageTemplateSelectionTable(props: { packages: PackageTemplate[], selectedPackage?: PackageTemplate, onPackageTemplateSelected: (p: PackageTemplate) => void }) {
    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Package Name</TableCell>
              <TableCell>User Based</TableCell>
              <TableCell>Max Input Tokens</TableCell>
              <TableCell>Max Output Tokens</TableCell>
              <TableCell>Max User Prompt Chars</TableCell>
              <TableCell>Max Words in Editing Topic</TableCell>
              <TableCell>Max Guidance Chars</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.packages.map((p) => (
              <TableRow key={p.packageName}>
                <TableCell width={50}>{<Radio name="package-selected" checked={ props.selectedPackage == p } onChange={ e => props.onPackageTemplateSelected(p) }/>}</TableCell>
                <TableCell>{p.packageName}</TableCell>
                <TableCell>{(p.userBased) + ""}</TableCell>
                <TableCell>{renderPackageTemplateFieldValue(p.maxInputTokens)}</TableCell>
                <TableCell>{renderPackageTemplateFieldValue(p.maxOutputTokens)}</TableCell>
                <TableCell>{renderPackageTemplateFieldValue(p.maxUserPromptChars)}</TableCell>
                <TableCell>{renderPackageTemplateFieldValue(p.maxWordsInEditingTopic)}</TableCell>
                <TableCell>{renderPackageTemplateFieldValue(p.maxGuidanceChars)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
}
function EttoConfigurePackage(props: { ettoPackage: EttoPackage, onPackageConfirmed: (p: EttoPackage) => void, onLicenseAgreementUpdated: (p: EttoPackage) => void, showLicenseAgreement: boolean }) {
    let [ettoPackage, setEttoPackage] = useState<EttoPackage>(props.ettoPackage)
    let [overridePackageDefaults, setOverridePackageDefaults] = useState<boolean>(false)

    useEffect(() => {
        setEttoPackage(props.ettoPackage)
    }, [props.ettoPackage])

    function patchPackage<U extends keyof EttoPackage>(key: U, value: EttoPackage[U]): EttoPackage {
        let newState = {
            ...ettoPackage,
            [key]: value
        }
        setEttoPackage(newState)
        
        return newState
    }

    function isPackageValid(): boolean {
        return isLicesningAgreementValid()
            && notNullAndGteZero(ettoPackage.maxGuidanceChars)
            && notNullAndGteZero(ettoPackage.maxInputTokens)
            && notNullAndGteZero(ettoPackage.maxOutputTokens)
            && notNullAndGteZero(ettoPackage.maxUserPromptChars)
            && notNullAndGteZero(ettoPackage.maxWordsInEditingTopic)
    }

    function notNullAndGteZero(val: number | null) {
        return val != null && val >= 0
    }

    function isLicesningAgreementValid(): boolean {
        return (props.showLicenseAgreement && ettoPackage.userCount != null && ettoPackage.userCount > 0) || !props.showLicenseAgreement
    }

    function handlePackageConfirm() {
        if(isPackageValid()) {
            props.onPackageConfirmed(ettoPackage)
        }
    }

    function nullToEmpty(val: any): any {
        // react input elements do not like "null" in the value attribute. 
        // react warns of controlled to uncontrolled input conversion
        if(val == null) {
            return ""
        }
        return val
    }

    return (<>{ ettoPackage != null &&
        <div>
            <Accordion expanded={ props.showLicenseAgreement } disabled={ !props.showLicenseAgreement } disableGutters>
                <AccordionSummary aria-controls="panel1-content" id="panel1-header" expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Licensing Agreement</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={ 1.5 }>
                    <Grid item xs={ 6 }>                               
                        <TextField
                                type="number"
                                label="User Count"
                                helperText="Premium package tiers input and output tokens are alloted based on count of users purchased in the Etto licensing agreement"
                                value={ nullToEmpty(ettoPackage.userCount) }
                                onChange={ e => {
                                    props.onLicenseAgreementUpdated(patchPackage("userCount", parseNumberNullable(e.target.value)))
                                }}
                                sx={{ width: 500 }}
                            />
                        </Grid>
                </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={ isLicesningAgreementValid() } disableGutters>
                <AccordionSummary aria-controls="panel1-content" id="panel1-header" expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Package Configuration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box marginBottom={2}>
                        <FormControlLabel control={<Checkbox onChange={ e => setOverridePackageDefaults(Boolean(e.target.checked)) }/>} label="Custom package" />
                        {
                            overridePackageDefaults &&
                            <Alert severity="warning">Editing a package should only be used in special circumstances (eg. Heretto, Partner, etc).</Alert>
                        }
                    </Box>
                    <Grid container spacing={ 1.5 }>
                        <Grid item xs={ 6 }>                               
                            <TextField
                                    type="number"
                                    label="Input Token Limit" 
                                    value={ nullToEmpty(ettoPackage!.maxInputTokens)} 
                                    helperText=" "
                                    disabled={!overridePackageDefaults}
                                    onChange={ e => patchPackage("maxInputTokens", parseNumberNullable(e.target.value)) }
                                    sx={{ width: 350 }}
                                />
                        </Grid>
                        <Grid item xs={ 6 }>                                
                            <TextField
                                    type="number"
                                    label="Output Token Limit" 
                                    value={ nullToEmpty(ettoPackage!.maxOutputTokens)}
                                    placeholder="minutes"
                                    helperText=" "
                                    disabled={!overridePackageDefaults}
                                    onChange={ e => patchPackage("maxOutputTokens", parseNumberNullable(e.target.value)) }
                                    sx={{ width: 350 }}
                                />

                        </Grid>
                        <Grid item xs={ 6 }>
                            <TextField
                                type="number"
                                label="User Prompt Limit (chars)" 
                                value={ nullToEmpty(ettoPackage?.maxUserPromptChars) }
                                placeholder="minutes"
                                helperText="Character limit for the prompt when working with Etto"
                                disabled={!overridePackageDefaults}
                                onChange={ e => patchPackage("maxUserPromptChars", parseNumberNullable(e.target.value)) }
                                sx={{ width: 350 }}
                            />
                        </Grid>
                        <Grid item xs={ 6 }>
                            <TextField
                                type="number"
                                label="Max Words in Editing Topic" 
                                value={ nullToEmpty(ettoPackage!.maxWordsInEditingTopic) }
                                placeholder="minutes"
                                helperText="Word limit for Topic where Etto is being used"
                                disabled={!overridePackageDefaults}
                                onChange={ e => patchPackage("maxWordsInEditingTopic", parseNumberNullable(e.target.value)) }
                                sx={{ width: 350 }}
                            />
                        </Grid>
                        <Grid item xs={ 6 }>
                            <TextField
                                type="number"
                                label="Guidance Limit (chars)" 
                                value={ nullToEmpty(ettoPackage!.maxGuidanceChars) }
                                placeholder="minutes"
                                helperText="Character limit for the customized guidance (configurable in CCMS Etto Config)"
                                disabled={!overridePackageDefaults}
                                onChange={ e => patchPackage("maxGuidanceChars", parseNumberNullable(e.target.value)) }
                                sx={{ width: 350 }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Box display="flex" justifyContent="flex-end" marginTop={3} marginBottom={5}>
                <Button variant="contained" onClick={handlePackageConfirm} disabled={ !isPackageValid() }>Set package</Button>
            </Box>
        </div>}</>
    )
}

function parseNumberNullable(str?: string): number | null {
    if(str == null || str === "") {
        return null
    }
    return Number(str)
}

function UnknownPackageNameAlert(props: { onBypassAlertClicked: () => void }) {
    return (
        <Alert variant="filled" severity="error">
            The CCMS is configured with an Etto Package, but the package name configured on the CCMS does not match any of the defined packages. This likely means one or more package names were changed and the CCMS has an "old" package name.
            <br /><br />
            The resolution steps for this should be discussed. If it has been decided to reconfigure the CCMS, you can <span onClick={ props.onBypassAlertClicked }>click here</span> to bypass this message and reselect a new package.
         </Alert>
    )
}

export default function() {
    const client = useContext(OrgClientInContext)
    const { enqueueSnackbar } = useSnackbar()

    const [selectedPackageTemplate, setSelectedPackageTemplate] = useState<PackageTemplate>()
    const [ettoPackage, setEttoPackage] = useState<EttoPackage>()
    const [unknownPackageName, setUnknownPackageName] = useState<boolean>(false)

    function handlePackageTemplateSelect(template: PackageTemplate) {
        setSelectedPackageTemplate(template)
        setEttoPackage(constructPackage(template, 0))
    }

    function findPackageTemplateByPackageName(name: string): PackageTemplate | undefined {
        return package_templates.find(pt => pt.packageName === name)
    }

    function handleLicenseAgreementUpdated(p: EttoPackage) {
        setEttoPackage(constructPackage(selectedPackageTemplate!, p.userCount!))
    }

    function onPackageConfirmed(p: EttoPackage) {
        client.etto.setPackage(p).then((r: any) => {
            enqueueSnackbar("The package was set on the CCMS", { variant: "success" })
        }).catch((e: any) => {
            enqueueSnackbar("Failed to set the package", { variant: "error" })
        })
    }

    useEffect(() => {
        client.etto.getPackage().then((p: EttoPackage) => {
            if(p == null) {
                return
            }
            setEttoPackage(p)
            let pt = findPackageTemplateByPackageName(p.packageName)
            if(pt == null) {
                setUnknownPackageName(true)
            } else {
                setSelectedPackageTemplate(pt)
            }
        }).catch((e: any) => {
            enqueueSnackbar("There was a problem getting the etto package", { variant: "error" })
        })
    }, [])

    let placeHolderSkeleton =
        <FeatureSection
            featureName=""
            featureDescription=""
            content={""}
        />

    return (
            <FeatureSection
                featureName="Etto (Copilot) Package"
                featureDescription="In addition to configuring the package here, an admin will need to enable Etto on the CCMS. For additional details on packages, go to here.heretto.com"
                content={
                    <div>
                    {
                        unknownPackageName ? <UnknownPackageNameAlert onBypassAlertClicked={ () => setUnknownPackageName(false) }/> : 
                        <>
                            <EttoPackageTemplateSelectionTable 
                                packages={package_templates} 
                                selectedPackage={ selectedPackageTemplate } 
                                onPackageTemplateSelected={handlePackageTemplateSelect} />
                            { 
                                (selectedPackageTemplate && ettoPackage) &&
                                    <EttoConfigurePackage 
                                        ettoPackage={ettoPackage} 
                                        onPackageConfirmed={onPackageConfirmed}
                                        onLicenseAgreementUpdated={ handleLicenseAgreementUpdated } 
                                        showLicenseAgreement={selectedPackageTemplate.userBased}/>
                            }
                        </>
                    }
                    </div>
                }
            />
    )
}