
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { DeployReportRequest, Organization } from "../types";
import { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { OrgClientInContext } from '../../index'
import React from "react";
import ClosableToastAction from "../basic/ClosableToastAction";

export default function DeployReportButtonAndDialog(props: { org: Organization }) {
    const client = useContext(OrgClientInContext)
    const { enqueueSnackbar } = useSnackbar()

    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [deployRequest, setDeployRequest] = useState<DeployReportRequest>({
        sourceWorkspaceId: "",
        sourceReportId: ""
    })

    const isDeployRequestValid = (): boolean => {
        return notNullOrEmpty(deployRequest.sourceWorkspaceId)
            && notNullOrEmpty(deployRequest.sourceReportId)
    }

    const deploy = () => {
        setDialogIsOpen(false)
        client.powerbi.deployReport(deployRequest)
        enqueueSnackbar("Deploying the new report", { variant: "info", persist: true, action: ClosableToastAction })
    }

    return (
        <>
        <Button onClick={ () => setDialogIsOpen(true) } variant="outlined">Deploy Report</Button>
        <Dialog open={dialogIsOpen}>
            <DialogTitle>Deploy Report</DialogTitle>
            <DialogContent>
                <Alert severity="info" sx={{ marginBottom: 4 }}>
                    This will clone a report from the source workspace into the org's existing workspace. It will make this new report the one that is visible in the CCMS and delete the currently displayed report.
                </Alert>
                <Grid container spacing={1.5}>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            label="Source Workspace ID"
                            value={ deployRequest.sourceWorkspaceId }
                            helperText="The workspace location where the source report is"
                            onChange={e => {
                                setDeployRequest(prevState => ({
                                    ...prevState,
                                    sourceWorkspaceId: e.target.value
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            label="Source Report ID"
                            value={ deployRequest.sourceReportId }
                            helperText="The report to clone into the existing org workspace and rebind to the org's dataset"
                            onChange={e => {
                                setDeployRequest(prevState => ({
                                    ...prevState,
                                    sourceReportId: e.target.value
                                }))
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setDialogIsOpen(false)}>
                    Cancel
                </Button>
                <Button autoFocus onClick={ deploy } disabled={!isDeployRequestValid()}>
                    Deploy
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

function notNullOrEmpty(thing: any): boolean {
    return typeof thing != "undefined" && thing
}