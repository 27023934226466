import './App.css';
import OrgList from './modules/OrgList';
import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Login from './modules/Login';
import { GoogleSigninResponse } from "./modules/GoogleSSO"
import { Organization } from './modules/types';
import { getAllOrgs, setGlobalNotOkHandler, getTokenStatus } from './Heretto';
import { useSnackbar, SnackbarKey } from 'notistack';
import HerettoLogoSubtle from "./heretto-logo-subtle.png"

let loginScreenSnackbarKeys = new Array<SnackbarKey>()

function App() {
    const existingGoogleJwt = window.sessionStorage.getItem("google_jwt")
    let orgUuid: string | undefined = useParams().orgUuid
    const [ orgs, setOrgs] = useState<Array<Organization>>()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [ isSignedin, setIsSignedIn ] = useState(existingGoogleJwt != null)

    useEffect(() => {
      if(isSignedin) {
        getAllOrgs()
          .then(mutateOrgs)
          .then(setOrgs)

        loginScreenSnackbarKeys.forEach(closeSnackbar) // ensure login screen related snackbars don't persist after changing screen
      }    
    }, [ isSignedin ])

    useEffect(() => {
      setGlobalNotOkHandler((error) => {
        if(error.status === 401 && !error.fromGateway) {
          window.sessionStorage.removeItem("google_jwt")
          setIsSignedIn(false)
          loginScreenSnackbarKeys.push(enqueueSnackbar("Your session has expired", { variant: "warning" }))
        } else {
          loginScreenSnackbarKeys.push(enqueueSnackbar("A network error has occured", { variant: "error" }))
        }
      })
    }, [ isSignedin ])

    const OrgEmptyState = (
      <div className="orgEmptyState">
        <div></div>
        <img src={ HerettoLogoSubtle } alt="" />
        <div>select an organization</div>
      </div> 
    )

    const App = <>
        <div className="nav">
          <OrgList orgs={ orgs } />
        </div>
        <div className="main">
          { 
            orgUuid ? <Outlet /> : OrgEmptyState
          }
        </div>
    </>

    function handleSignin(response: GoogleSigninResponse) {
      window.sessionStorage.setItem("google_jwt", response.credential)

      getTokenStatus().then(status => {
        if(status.ok) {
          setIsSignedIn(true)
        } else if(status.status === 401) {
          loginScreenSnackbarKeys.push(enqueueSnackbar("This account is not authorized", { variant: "error" }))
          window.sessionStorage.removeItem("google_jwt")
        } else {
          loginScreenSnackbarKeys.push(enqueueSnackbar("Login failed for unknown reason", { variant: "error" }))
          window.sessionStorage.removeItem("google_jwt")
        }
      })
    }

    return (
        <div className="app">
          {
            isSignedin ? App : <Login onSignin={ handleSignin } />
          }
        </div>
    );
}

/**
 * A list of orgs and fields to override.
 * 
 * A use case:
 * In production, the customer Arthex mistakenly has a suffix of "dev" in their org name - which is confusing
 * and may cause dashboard users to click the wrong one. Changing the org name on the ccms is difficult, maybe impossible.
 * Instead, we change the org name that is displayed on the dashboard clientside - updating the CCMS or the master table of orgs is not appropriate.
 * 
 * Might be useful for other orgs in the future. Sucks it's hardcoded here - but it's fine for now - consider moving this.
 */
const OrgFieldOverrides = {
  "3adaaebc-1e61-441d-8fdc-0c6b0e232e7e": {
    org_id: "arthex"
  },
  "b1072936-492e-44a2-9be6-4cc8fb7733e0": {
    org_id: "arthex-dev"
  }
}

function mutateOrgs(orgs: Array<Organization>): Array<Organization> {
  let entries = Object.entries(OrgFieldOverrides)

  for(const [orgIdToMutate, fieldsToMutate] of entries) {
    for(const org of orgs) {
      if(orgIdToMutate == org.org_uuid) {
        mutate(org, fieldsToMutate)    
      }
    }
  }

  return orgs

  function mutate(org: Organization, fieldsToMutate: object) {
    for(const [fieldName, value] of Object.entries(fieldsToMutate)) {
      org[fieldName as keyof Organization] = value
    }
  }
}

export default App;
