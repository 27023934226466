import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import FeatureSection from './FeatureSection'
import styles from "./InAppNotifications.module.css"

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format } from "date-fns"
import { InAppNotification } from './types'
import { OrgClientInContext } from '../index'
import Panel from './basic/Panel'
import { useSnackbar } from 'notistack'

function CreateNotificationBtnWithDialog(props: { onCreate: () => void }) {
    const client = useContext(OrgClientInContext)
    const { enqueueSnackbar } = useSnackbar()

    const [ startDate, setStartDate ] = useState<Date | null>(new Date())
    const [ startTime, setStartTime ] = useState<Date | null>(new Date())
    const [ duration, setDuration ] = useState<string | null>()

    const [ createMsgDialogIsOpen, setCreateMsgDialogIsOpen ] = useState(false)

    const createNotification = () => {
        if(!areUserFieldsValid()) {
            enqueueSnackbar("All fields are required", { variant: "warning" })
            return
        }

        let start = combineDates()?.getTime();
        client.notifications.createUpgradeNotification(start, duration).then((resp: any) => {
            if(resp.ok) {
                setCreateMsgDialogIsOpen(false)
                props.onCreate()
                enqueueSnackbar('The notification was sent', { variant: "success" })
            }
        })
    }

    const combineDates = () => {
        if(startDate != null && startTime != null) {
            let dt = new Date(startDate);
            dt.setHours(startTime.getHours())
            dt.setMinutes(startTime.getMinutes())
            dt.setSeconds(0)
            return dt;
        }
    }

    const areUserFieldsValid = () => {
        return startDate != null && startTime != null && duration != null
    }

    return (
        <>
            <Button variant="contained" onClick={ () => setCreateMsgDialogIsOpen(true) }>Create Notification</Button>
            <Dialog open={ createMsgDialogIsOpen }>
                <DialogTitle>Send a Maintenance Notification</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={ AdapterDateFns }>
                        <Grid container spacing={ 1.5 }>
                            <Grid item xs={ 6 }>                               
                                <DesktopDatePicker
                                    label="Start date"
                                    inputFormat="MM/dd/yyyy"
                                    value={ startDate }
                                    onChange={ val => {
                                        setStartDate(val)
                                    }}
                                    renderInput={params => <TextField {...params} helperText="Enter as your local time. The time is automatically converted to the users local time when displayed in the CCMS" fullWidth margin="dense" />}
                                />
                            </Grid>
                            <Grid item xs={ 6 }>                                
                                <TimePicker
                                    label="Start time"
                                    inputFormat="hh:mm"
                                    value={ startTime }
                                    onChange={ val => {
                                        setStartTime(val) 
                                    }}
                                    renderInput={params => <TextField {...params} fullWidth margin="dense" />}
                                />
                            </Grid>
                            <Grid item xs={ 6 }>
                                <TextField
                                    type="number"
                                    label="Duration" 
                                    value={ duration }
                                    placeholder="minutes"
                                    onChange={ e => setDuration(e.target.value) }
                                    helperText="The total expected minutes to perform the maintenance"
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ () => setCreateMsgDialogIsOpen(false) }>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={ createNotification }>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function ActiveNotification(props: { notification: InAppNotification, onDelete: () => void }) {
    const client = useContext(OrgClientInContext)
    const { enqueueSnackbar } = useSnackbar()

    const deleteNotification = () => {
        client.notifications.removeNotification(props.notification.notificationId).then((resp: any) => {
            if(resp.ok) {
                props.onDelete()
                enqueueSnackbar('The notification was deleted', { variant: "success" })
            }
        })
    }

    let startDate = props.notification.message.values[0]
    let startTime = props.notification.message.values[1]

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Maintenance Notification
                </Typography>
                <Typography color="text.secondary" gutterBottom>
                    Scheduled for { format(startDate, "LLL do") } at { format(startTime, "h:mmaaa") }
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={ deleteNotification }>Cancel</Button>
            </CardActions>
        </Card>
    )
}

export default function InAppNotifications() {
    const client = useContext(OrgClientInContext)
    const [ notifications, setNotifications ] = useState<Array<InAppNotification>>([])

    useEffect(() => {
        fetchNotifications()
    }, [client]) 

    const fetchNotifications = () => {
        client.notifications.getMyNotifications().then(setNotifications)
    }

    const ControlCreateNotification = (
        <>
            {
                notifications.length > 0 && <CreateNotificationBtnWithDialog onCreate={ fetchNotifications } />
            }            
        </>
    )

    const EmptyState = (
        <Panel className={ styles.emptyState }>
            <div className={ styles.emptyStateMsg }>
                <SentimentNeutralIcon sx={{ fontSize: 80 }} />
                <div>There are no active notifications</div>
            </div>
            <CreateNotificationBtnWithDialog onCreate={ fetchNotifications } />
        </Panel>
    )

    const NotificationList = (
        <Stack direction="row" spacing={2}>
            {
                notifications.length === 0 ? EmptyState :
                    notifications.map(n => <ActiveNotification notification={ n } key={ n.notificationId } onDelete={ fetchNotifications } />)
            }
        </Stack>
    )

    return (
        <FeatureSection
            featureName='In-app Notifications' 
            featureDescription='Messages displayed to the users when they log in. All notifications are cleared if the instance is restarted.' 
            control={ ControlCreateNotification }
            content={ NotificationList } 
        />
    )
}