import { useState } from "react"
import { Organization } from "./types"
import { useNavigate } from "react-router-dom"
import ListSelectable from "./basic/ListSelectable";
import styles from "./OrgList.module.css"

interface OrgListProps {
    orgs: Array<Organization> | undefined
}

function EmptyState() {
    return (
        <div className={ styles.emptyState }>
            no orgs found
        </div>
    )
}

export default function OrgList(props: OrgListProps) {
    const navigate = useNavigate()
    const [ searchTerm, setSearchTerm ] = useState<string>("")

    const onClickOrg = (org: Organization) => {
        navigate(`/orgs/${ encodeURIComponent(org.org_uuid) }`)
    }

    const filterOrgs = (): Array<Organization> => {
        return props.orgs!.filter(o => 
             o.cms_api_host?.toLowerCase().includes(searchTerm) 
                || o.org_id.toLowerCase().includes(searchTerm)
                || o.org_uuid === searchTerm
        )
    }

    let filteredOrgs = searchTerm === "" ? props.orgs! : filterOrgs()
    
    const isLoading = props.orgs == null

    const dummySkeletonPlaceholder = (
        <ListSelectable 
            entries={ [...Array(12)].map(e => {return {}}) } 
            titleProducer={ g => undefined } 
            descriptionProducer={ g => undefined }
            keyProducer={ g => undefined } 
        />
    )

    return (
        <div className={ styles.orgList }>
            <div className={ styles.header }>
                <input type="text" className={ styles.searchInput } value={ searchTerm } onChange={ e => setSearchTerm(e.target.value.toLowerCase()) } placeholder="search orgs" />
            </div>

            {
                isLoading ? dummySkeletonPlaceholder :
                    filteredOrgs.length === 0 ? 
                        <EmptyState /> :             
                            <ListSelectable 
                                entries={ filteredOrgs } 
                                titleProducer={ g => g.org_id } 
                                descriptionProducer={ g => g.cms_api_host }
                                keyProducer={ g => g.org_uuid }
                                onSelect={ onClickOrg } 
                            />
            }
        </div>
    )
}